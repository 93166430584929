import * as React from 'react'
import EventDetails from 'components/EventDetails/EventDetails'
import PennyLaneResearchRetreatContent from '../../../content/events/PennyLaneResearchRetreat'
import Seo from '../../components/Seo'

const PennyLaneResearchRetreat = () => {
  return <EventDetails content={PennyLaneResearchRetreatContent} />
}

export default PennyLaneResearchRetreat

export const Head = () => {
  const { seo } = PennyLaneResearchRetreatContent
  return (
    <Seo
      title={seo.title}
      description={seo.description}
      image={seo.image}
      imageBasePath="/events/"
    />
  )
}

export default {
  seo: {
    title: `PennyLane Research Retreat`,
    description: `The PennyLane Research Retreat is an opportunity to spend 5 days working with world’s top quantum researchers at Xanadu’s Toronto office.`,
    image: 'pennylane-research-retreat/PennyLaneResearchRetreatLogo-white.png',
  },
  hero: {
    title: `Events`,
    description: `Discover your next research idea faster`,
    image: `PennyLaneResearchRetreatLogo.png`,
    imageAlt: `PennyLane Research Retreat, October 23rd to 27th, 2023`,
  },
  overview: {
    sectionTitle: `Overview`,
    description: `
This is not a conference.

The PennyLane Research Retreat is a unique opportunity to work with some of the most amazing quantum researchers in the world. It’s a place to develop ideas that are ahead of their time. There will be a few intriguing talks on the menu, but no obligation to attend or give your own: the schedule is designed for focused research and collaboration.

At Xanadu, we are creating the optimal software for quantum researchers—so that you can discover new ideas faster. During the Retreat our researchers will be fully engaged to help you rapidly iterate from the spark of an idea to a well-developed prototype. And our developers will be on hand to help debug your code or even implement new features. Along the way, we’ll learn from your research process so we can build PennyLane better in the future.

Join a small, hand-picked group of the world’s top QC researchers at our brand new Xanadu office in vibrant downtown Toronto, and take your research ideas to the next level.
  `,
  },
  participantsSection: {
    sectionTitle: `Program Committee`,
    showImages: true,
    participants: [
      // `image`, `linkedInUrl`, `twitterUrl`, `githubUrl`, and `googleScholarUrl` are optional fields.
      // The order that participants are listed here is the order they will appear on the page.
      {
        name: `Josh Izaac`,
        affiliation: `Director of Product, Xanadu`,
        image: `josh-izaac.jpg`,
        twitterUrl: `https://twitter.com/3rdquantization`,
        githubUrl: `https://github.com/josh146`,
        googleScholarUrl: `https://scholar.google.com/citations?&user=pEj09c4AAAAJ`,
        linkedInUrl: `https://www.linkedin.com/in/joshizaac/`,
      },
      {
        name: `Juan Miguel Arrazola`,
        affiliation: `Algorithms Senior Team Lead, Xanadu`,
        image: `juan-miguel-arrazola.jpg`,
        twitterUrl: `https://twitter.com/ixfoduap`,
        githubUrl: `https://github.com/ixfoduap`,
        googleScholarUrl: `https://scholar.google.ca/citations?user=10qgYZMAAAAJ&hl=en&oi=ao`,
        linkedInUrl: ``,
      },
      {
        name: `Maria Schuld`,
        affiliation: `Quantum Machine Learning Lead, Xanadu`,
        image: `maria-schuld.jpeg`,
        twitterUrl: `https://twitter.com/MariaSchuld`,
        githubUrl: `https://github.com/mariaschuld`,
        googleScholarUrl: `https://scholar.google.com/citations?user=_ih_hwUAAAAJ`,
        linkedInUrl: `https://za.linkedin.com/in/maria-schuld-7857b070`,
      },
      {
        name: `Nathan Killoran`,
        affiliation: `CTO–Software, Xanadu`,
        image: `nathan-killoran.jpg`,
        twitterUrl: `https://twitter.com/PennyLaneAI`,
        githubUrl: `https://github.com/co9olguy/`,
        googleScholarUrl: `https://scholar.google.com/citations?user=4IXbCYMAAAAJ`,
        linkedInUrl: ``,
      },
    ],
  },
  sponsors: {
    title: `Sponsors Section Title`,
    description: `Sponsors section description`,
    logos: [
      // {
      //   url: `https://www.xanadu.ai/`,
      //   image: `xanadu-bw.png`,
      //   imageAlt: `Xanadu`,
      // },
    ],
  },
  eventSchedule: {
    sectionTitle: `Schedule`,
    timezoneTooltip: `Eastern Standard Time`,
    // The column headers in this list appear after the Time column.
    columnHeaders: ['Event', 'Participants'],
    // When updating the category of a row, double check
    // that the category name matches a category here.
    // Otherwise, the correct colour will not appear on the row
    categories: [`Opening & Closing Ceremonies`, `Competition Rounds`],
    schedule: [
      // {
      //   day: `November 7, 2023`,
      //   scheduleRows: [
      //     {
      //       category: `Opening & Closing Ceremonies`,
      //       time: `11:30am - 12:00pm`,
      //       columnsAfterTime: [
      //         { text: `Opening Ceremony`, description: `` },
      //         {
      //           text: `All Teams`,
      //           description: `All teams should join this opening session on Twitch (details to be provided) to learn about the event before diving into the competition.`,
      //         },
      //       ],
      //     },
      //     {
      //       category: `Competition Rounds`,
      //       time: `12:00pm`,
      //       columnsAfterTime: [
      //         {
      //           text: `All-in Competition (Round 1) begins`,
      //           description: ``,
      //         },
      //         {
      //           text: `All Teams`,
      //           description: `All teams start the first round of the coding competition.`,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   day: `November 8, 2023`,
      //   scheduleRows: [
      //     {
      //       category: `Competition Rounds`,
      //       time: `12:00pm`,
      //       columnsAfterTime: [
      //         {
      //           text: `All-in Competition (Round 1) ends`,
      //           description: ``,
      //         },
      //         {
      //           text: `All Teams`,
      //           description: `The first round of the competition ends.`,
      //         },
      //       ],
      //     },
      //     {
      //       category: `Competition Rounds`,
      //       time: `1:00pm`,
      //       columnsAfterTime: [
      //         {
      //           text: `Semifinals Competition (Round 2) begins`,
      //           description: ``,
      //         },
      //         {
      //           text: `Top 50% of Teams`,
      //           description: `The top 50% of teams start the second round of the coding competition.`,
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  location: {
    sectionTitle: `Additional Information`,
    description: `
This Retreat is intended to be a dedicated, focused research time. We are asking all participants to come only if they can commit to a week of minimal emails, video calls or other external deliverables. Our team members will be making the same commitment.

The Retreat will take place at the Xanadu offices at 777 Bay Street, Toronto. Travel to Toronto and accommodation for participants will be organized by Xanadu staff. While you're here you'll have a desk in the office with wifi, lunch and snacks all provided.

We hope you can join us!
  `,
  },
}
